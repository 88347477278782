import React from 'react'
import Select from 'react-select'

const MultiPicker = ({ selected, options, name }) => {
  return (
    <>
      <Select
        name={`quiz_team[${name}]`}
        options={options}
        defaultValue={selected}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="- Select -"
      />
    </>
  )
}

export default MultiPicker
