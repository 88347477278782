// src/controllers/hello_controller.js
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'country',
    'ic',
  ]
  connect() {
    this.hasIcTarget ? this.toggleIC() : null
  }

  toggleIC() {
    if (this.countryTarget.value == 'MY') {
      this.icTarget.disabled = false
    } else {
      this.icTarget.disabled = true
    }
  }
}
